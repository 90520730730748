import React, { PureComponent, forwardRef } from 'react';
import { connect } from 'react-redux'
import { CSSTransition } from 'react-transition-group';
import { loadingIcon, gqlError, titleCase } from '../../../helpers';
import { PatientDetail } from '../../../actions';
import { Tree, Card, Row, Col, Spin, Button, Typography, Icon, Affix,
         Modal, Progress, Empty, message, Result, Tooltip} from 'antd';

import { PatientDiveBadge } from '../shared/PatientDiveBadge';
import RefinePhenotype from '../caregiver/RefinePhenotype';
import axios from 'axios';
import Rest from '../../../services/Rest';
import _ from 'lodash';
import './CaseAnalysisStatus.css';

const CancelToken   = axios.CancelToken;
const { Text }      = Typography;
const { TreeNode }  = Tree;

const CAREGIVER     = 'CAREGIVER';
const LAB_USER      = 'LAB_USER';
const EXCUSE_1      = 'Awaiting phenotype refinement from caregiver'
const EXCUSE_2      = 'Awaiting confirmation from caregiver'

// const HELP_MESSAGE  = '';
const DISABLED      = 'Dive is disabled';
const ERROR_1       = 'First-pass analysis error';
const ERROR_2       = 'refined analysis error';
const QUEUED_1      = 'Queued first-pass analysis ';
const QUEUED_2      = 'Queued refined analysis'
const RUNNING_1     = 'Running first-pass analysis';
const RUNNING_2     = 'Running refined analysis';
const DONE_1        = 'Need caregiver to refine phenotype(s)';
const DONE_2        = 'Need caregiver to review disease(s)';
const CONFIRMED     = 'Click to review dive';
const REANALYSIS    = 'Sent to lab';

const flexStyle = {
    marginTop:20, 
    alignItems:'stretch'
};

const centerStyle = {
    position:'absolute',
    width:'100%',
    top:'35%'
}

const DISP = {
    // Some disambiguation is necessary. 
    // COMPLETE = pipeline completed
    // DONE     = user acknowledged completion 

    'CREATED'               : QUEUED_1,
    'QUEUED'                : QUEUED_1,
    'RUNNING'               : RUNNING_1,
    'COMPLETE'              : DONE_1,
    'DONE'                  : DONE_1,
    'ERROR'                 : ERROR_1,
    'DISABLED'              : DISABLED,
    'CONFIRMED'             : CONFIRMED,
    'REANALYSIS'            : REANALYSIS,

    // Analysis Pipeline (2) 
    'DONE-CREATED'          : QUEUED_2,
    'DONE-QUEUED'           : QUEUED_2,
    'DONE-RUNNING'          : RUNNING_2,
    'DONE-COMPLETE'         : DONE_2,
    'DONE-DONE'             : DONE_2,
    'DONE-ERROR'            : ERROR_2,
    'CONFIRMED-CONFIRMED'   : CONFIRMED,
    'REANALYSIS-REANALYSIS' : REANALYSIS
};

const default_state = {
    case_focus:null,
    analysis_detail_focus: null,    // user selected analysis 
    analysis_detail : null,         // detailed information on focused analysis (1,2)
    display_disease : null,         // disease information (caregiver)
    display_variants: null,         // variant information (lab user) 
    show_excuse: null,              // intermittent state messaging 
    case_dives: null,   
    survey_open: false,
    survey_visible: false               
};

class CaseAnalysisLirical extends PureComponent {
    constructor(props) {
        super(props);
        
        this.state = {...default_state}

        // cancel request 
        this.request_source = CancelToken.source();
        this.interval = null;     
    }

    
    static searchDiveData(case_dives, selection){
        // some basic error check, 
        // const {case_dives} = this.state;
        
        if (!(selection && selection[0])) {
            return {
                object_type: 'UNKNOWN'
            } 
        } 

        // Return the object being searched 
        const [object_type, id] = selection[0].split('-');
        switch (object_type) {
            case 'case':
                return {
                    object_type,
                    payload:_.find(case_dives, {id:Number.parseInt(id)})
                }
            
            case 'dive':
                return {
                    object_type,
                    payload:_.chain(case_dives).map(c => c.dives)
                            .find(selection[0]).value()[selection[0]]
                }
            
            default:
                return {
                    object_type: 'UNKNOWN'
                } 
        }
    }
    
    generateDiveDisplay(dives) {
        const completion = dives.map(d => {
            if (d.status === 'ERROR' || d.state === 'DISABLED') {return 0}
            return ['CREATED', 'QUEUED', 'RUNNING', 
                    'COMPLETE', 'DONE', 'CONFIRMED', 
                    'REANALYSIS'].indexOf(d.status)
        });

        const stat = dives.map(d => d.status).join('-');
        const date = new Date(dives[0].time_started)
        const display_status  = _.get(DISP, stat, "");
        let progress_status = 'normal';
        
        if (display_status.toLowerCase().indexOf('error') !== -1) {
            progress_status = 'exception'
        } else if (display_status.toLowerCase().indexOf('running') !== -1) {
            progress_status = 'active';
        } else if (display_status.toLowerCase().indexOf('submitted') !== -1) {
            progress_status = 'success';
        } else if (display_status.toLowerCase().indexOf('review') !== -1) {
            progress_status = 'normal';
        }

        return (
            <div style={{display:'flex', flexDirection:'column'}}>
                <div style={{position:'relative', whiteSpace: 'pre-wrap'}}>
                    <Text strong>Dive #{dives[0].id}:&nbsp;</Text><br/>
                    <span>{display_status}</span>
                </div>
                
                <Progress size="small" status={progress_status} 
                    percent={Math.min(Math.round(_.sum(completion)/5 * 100), 100)}
                    format={percent => `${percent/100 * 5}/5`}/>
                <div>
                    <Text style={{fontSize:'small'}} type='secondary'>
                        Date started: {date.toLocaleDateString()}     
                    </Text>
                </div>
            </div>
        )
    }

    static analysisDive(cases, view) {

        function status_filter(status) {
            const finished_states = new Set(['CONFIRMED', 'REANALYSIS']);
            switch (view) {
                case 'caregiver_explore': 
                    return !(finished_states.has(status));
                
                case 'caregiver_summary': 
                    return finished_states.has(status);

                case 'lab':
                default:
                    return true;
            }
        }   
        
        return _.reduce(cases, (acc_c, c) => {
            acc_c.push({
                id:c.id,
                name:c.name,
                dives: 
                    // has parent, push analysis into dive
                    _.reduce(c.analysis 
                        .filter(a => a.parent !== 0)
                        .filter(a => status_filter(a.status)), 
                        (acc_v2, a) => {
                            if (acc_v2['dive-'+a.parent]) {
                                acc_v2['dive-'+a.parent].push(a)
                            } else {
                                // interesting situation: 
                                // CONFIRMED, COMPLETE is an error but should be displayed
                                const error_state = c.analysis.filter(p => p.id === a.parent)[0] 
                                acc_v2['dive-'+error_state.id] = [error_state, a]
                            }
                    return acc_v2;
                    
                    // no parent => new dive definition 
                },  _.reduce(c.analysis 
                        .filter(a => a.parent === 0)
                        .filter(a => status_filter(a.status)), 
                        (acc_v1, a) => {
                        acc_v1['dive-'+a.id] = [a]
                        return acc_v1;
                } , {}))
            });
            return acc_c;
        }, []);
    }

    componentWillUnmount() {
        this.interval = null;
    }

    componentDidMount() {
        const {patient_id} = this.props;
        this.props.patients(patient_id);
   
        // set the initial state
        this.setState(default_state);
    }

    onCommentUpdate = (analysis_id, new_comment) => {
        const {analysis_detail} = this.state;
        Rest.modifyAnalysisComment(analysis_id, new_comment)
        .then(response => {
            message.success("Comment updated");

            // update analysis 
            const modified_analysis = [...analysis_detail[`analysis_${analysis_id}`]]
            modified_analysis[0].comment = new_comment;

            // merge new state into analysis detail 
            const modified_detail = {
                ...analysis_detail,
                [`analysis_${analysis_id}`]: modified_analysis
            }

            this.setState({analysis_detail:modified_detail});
        });
    }

    onTreeSelect = (selection) => {
        const {case_dives} = this.state; 
        const data = CaseAnalysisLirical.searchDiveData(case_dives, selection);
        const active_case = _.filter(case_dives, (d) => d.dives.hasOwnProperty(selection[0]));

        switch (data.object_type) {
            case 'case':
            default:
                this.setState({
                    case_focus:null,
                    analysis_detail:null,
                    analysis_detail_focus:null,
                    display_disease:null,
                    display_variants:null,
                    show_excuse:null
                });

                // cancel any requests;
                this.request_source.cancel();
                break;

            case 'dive':
                this.setState({
                    case_focus: active_case[0].id,
                    analysis_detail_focus: selection[0]
                });
                break;
        }
    }
    
    displayError(error) {

        // ignore cancellations 
        if (axios.isCancel(error)) {
            return; 
        }

        // TODO: need to catch generic/rest and graphql
        Modal.error({
            title:'Error',
            content: error.message || error.response.data
                .errors.map(d => d.message).join('\n'),
            onOk() {}
        });
    }

    analysisDetailCare(user, analysis_ids, cancel_token) {
        const {patient} = this.props;
        Rest.getAnalysisDetailCare(patient.id, analysis_ids, cancel_token)
            .then(response => {return gqlError(response)})
            .then(response => {
                const analysis_detail = response.data.data;
                this.setState({analysis_detail});  
                
                switch (user.role) {
                    case CAREGIVER:
                        // do nothing - displayed data
                        break;        

                    case LAB_USER:

                        var status = ""
                        for (const analysis in analysis_detail) {
                            // there's only a single analysis now 
                            status = analysis_detail[analysis][0].status;
                        }

                        if (status === 'DONE') {
                            // do nothing 
                            this.setState({
                                display_disease:null,
                                display_variants:null,
                                show_excuse:EXCUSE_1
                            });
                        }
                        return Promise.resolve(true);
                }
            })
            .catch((error) => {this.displayError(error)});  
    }

    componentDidUpdate(prevProps, prevState) {
        const {user}       = this.props; 
        const {case_dives} = this.state;
        const focus        = this.state.analysis_detail_focus;
        const p_focus      = prevState.analysis_detail_focus;
        const new_focus    = focus && p_focus !== focus;
        const cancel_token = this.request_source.token;
        
        // grab analysis with context dependent data 
        if (new_focus) {
            const dive = CaseAnalysisLirical.searchDiveData(case_dives, [focus]);
            this.analysisDetailCare(user, dive.payload.map(a => a.id), cancel_token);
        }
    };

    static getDerivedStateFromProps(props, state) {
        const {user, patient, view}                                 = props; 
        const {case_dives, analysis_detail, analysis_detail_focus}  = state

        let result = {};

        // tree construction, transform 
        // patient->case->analysis to 
        // patient->dives 
        if (patient && patient.case && !case_dives) {
            result['case_dives'] = CaseAnalysisLirical.analysisDive(patient.case, view)
        }

        // caregiver only: should we show disease ?
        const show_disease = user.role === CAREGIVER && 
                                analysis_detail_focus && 
                                analysis_detail && 
                                analysis_detail.disease_gene && 
                                analysis_detail.annotations;
    
        // caregiver only: (display diseases and genes)
        if (show_disease) {
            result['display_disease'] = analysis_detail.disease_gene.map((d,i) => {
                const search = _.chain(analysis_detail.annotations.hpo_disease)
                                .find({'disease_id':d['disease']}).value();
                
                let split_disease_name = search.disease_name.split(';')
                d['disease_name'] = search ? 
                    split_disease_name.shift().split(' ')
                    .map(s => {return s.split('-').map(t=>titleCase(t)).join('-')}).join(' ')
                    + (split_disease_name.length ? ';' + split_disease_name.join(';') : '')
                : '(Name not in database)';

                d['key'] = i;
                return d;
            });

            result['show_excuse'] = null;
        }

        // set excuse; 
        // set state 
        if (result !== {}) {
            return result
        }

        return null; 
    }

    setSurveyVisible(survey_visible) {
        this.setState({survey_visible});
    }

    openSurvey = () => {
        this.setState({survey_open:true});
    }

    closeSurvey = () => {
        this.setState({survey_open:false});
    }

    render() {
        const {patient_id, patient, view, user} = this.props; 
        const {analysis_detail, case_focus, analysis_detail_focus, case_dives, show_excuse,
            survey_open, survey_visible} = this.state;
        const case_keys  = case_dives ? case_dives.map(ca => 'case-'+ca.id) : undefined
        const treeStyle  = {minHeight:180, overflowX:'hidden'}
        const show_spin  = analysis_detail_focus && !analysis_detail;
        const show_empty = analysis_detail_focus == null;
        
        // this is a hacky way to get the last analysis can be #1 or #2 
        const analysis_ids = analysis_detail ? Object.keys(analysis_detail)
            .filter(k => k.startsWith('analysis'))
            .map(k => Number.parseInt(k.split('_')[1]))
            .sort((a, b) => a - b) : undefined 

        // calculate the number of dives 
        const num_dives = _.chain(case_dives)
            .map((d) => Object.keys(d.dives).length)
            .sum().value()

        // Weird that affix components needs a forwardRef 
        const ExcuseElement = forwardRef((props, ref) => {
            return <Result style={{marginTop:30}}
                icon={<Icon type="question-circle"></Icon>}
                title="RESULTS PENDING"
                subTitle={show_excuse || ''}/>
        });

        return (
        <Card style={{marginTop:20}}>
            {(patient && (Number.parseInt(patient_id) === Number.parseInt(patient.id))) ? 
            <Row>
                <Col span={24} offset={0}>
                    <Row>
                        <PatientDiveBadge patient={patient}/>    
                    </Row>

                    {case_dives && num_dives === 0 && 
                        <Result style={{marginTop:30}}
                            status="404"
                            title="NOT FOUND"
                            subTitle="No dives found"/>}

                    {case_dives && num_dives > 0 && 
                        <Row style={flexStyle} 
                             type="flex" justify="center" align="top">

                            {/* ------ Tree ------- */}
                            <Col span={5} style={treeStyle}>
                                <Tree defaultExpandedKeys={case_keys} onSelect={this.onTreeSelect}
                                    showIcon={false} showLine={false}>

                            {/* ------ Case ------- */}
                                {case_dives.map(_case => 
                                <TreeNode title={
                                    <span>
                                        <Text style={{float:'left'}} strong>GD ID:&nbsp;</Text> 
                                        <Tooltip title={_case.name}>
                                            <Text style={{float:'left', width:'50%', minWidth:100}} ellipsis={true}> {_case.name}</Text>
                                        </Tooltip>
                                    </span>} 
                                    key={`case-${_case.id}`}>

                            {/* ------ Dive ------- */}
                                {Object.keys(_case.dives)
                                    .sort((a,b) => {return Number.parseInt(b.split('-')[1]) - Number.parseInt(a.split('-')[1])})
                                    .map(dive_key => 
                                    <TreeNode style={{width:'100%'}}
                                        title={this.generateDiveDisplay(_case.dives[dive_key])}
                                        key={dive_key} className={'dive-status'}
                                        icon={<Icon type="question-circle"></Icon>}>
                                    </TreeNode>)}
                                </TreeNode>
                                )}
                                </Tree>
                            </Col>

                            {/* ------ Table ------- */}
                            <Col span={18} offset={1}>
                                
                                {/*  Caregiver View Only 
                                    -----------------------------------------------------------------------
                                    - Display the result of the dive session. (for Caregiver)
                                    - this includes disease, disease id, and genes affected
                                    - also the number of matching phenotypes 
                                */}
                                <Row>
                                   {analysis_ids && !show_excuse && <RefinePhenotype
                                         role={user.role} 
                                         showInfo={false}
                                         patient={patient} 
                                         patient_id={patient_id}
                                         case_id={case_focus}
                                         analysis_id={analysis_ids[0]}
                                         mode='report'
                                         set_survey_visible={(visible) => this.setSurveyVisible(visible)}
                                    />}
                                </Row>
                                
                                {show_excuse && 
                                    <Affix>
                                        <ExcuseElement/>
                                    </Affix>}

                                {/* Empty Container 
                                    -----------------------------------------------------------------------
                                    - Remind user to select from the Tree Widged to the 
                                      on the side
                                */}
                                {show_empty && 
                                    <CSSTransition in={show_empty} timeout={0} 
                                        classNames="result" unmountOnExit appear>
                                        <Empty 
                                            
                                            style={centerStyle}
                                            description={<span>Please pick a dive.</span>}/>
                                    </CSSTransition>}
                                
                                {/* Load Spinner */}
                                {show_spin && 
                                    <Row style={{textAlign:'center', ...centerStyle}}>
                                        <Spin indicator={loadingIcon} />
                                    </Row>}

                                    
                            </Col>
                        </Row>}

                        <Row style={{marginTop:20}}>
                            <Col span={12}>
                                <Button icon='left' type='primary' onClick={this.props.goHome}>BACK</Button>
                            </Col>
                            <Col span={12} style={{ textAlign: 'right' }}>
                                {survey_visible && 
                                    <Button type='primary' onClick={this.openSurvey}>RATE MY EXPERIENCE AND FINISH</Button>
                                }
                            </Col>
                        </Row>
                </Col>
                                    
            </Row>
            :
            <Row style={{textAlign:'center', margin:'10em'}}>
                <Spin indicator={loadingIcon} />
            </Row>}
            <Modal className="report-modal"  
                cancelButtonProps={{ style: { display: 'none' } }} 
                centered style={{visibility:'visible', height:'80%', width:'80%'}} 
                visible={survey_open} onOk={this.props.goHome} okText="DONE">
                <iframe 
                    style={{width:'100%', height: "100%", border:0} }
                src={`https://www.surveymonkey.com/r/P7H7GTF?user_id=${user.username}&case_id=${case_focus}`}></iframe>
            </Modal>

        </Card>)
    }
}

function mapStateToProps(store, ownProps) {
    let {PatientDetail, Auth} = store;
    return {
        user: Auth.info ? Auth.info : undefined,
        patient: 
            PatientDetail.data && 
            PatientDetail.data.patients ? PatientDetail.data.patients[0] : undefined
    };
  }

function mapDispatchToProps(dispatch, ownProps) {
    return {
        patients: (id) => {
            dispatch(PatientDetail(id, true));
        }
    }
};

export default connect(
    mapStateToProps,mapDispatchToProps
)(CaseAnalysisLirical);