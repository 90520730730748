import _ from 'lodash';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { diveTree, phenotypesToDrag, flexStyle, loadingIcon, gqlError } from '../../../helpers';
import {
    Collapse, Table, Rate, Card, Row, Col, Button, Spin, Alert, Select,
    Modal, Typography, Result, notification, Icon, message
} from 'antd';
import { PatientDetail, PatientDetailPhenotypes, startAnalysis, cancelAnalysis, completeAnalysis } from '../../../actions';
import { PatientDiveBadge } from '../shared/PatientDiveBadge';
import { PhenotypeCategory } from './DraggablePhenotypes';
import HPOSelector from '../../inputs/HPOSelector';
import Rest from '../../../services/Rest';
import axios from 'axios';
import { DragDropContext } from 'react-beautiful-dnd';
import ReactECharts from 'echarts-for-react';
import DiseaseLink from '../shared/DiseaseLink';

const CAREGIVER     = 'CAREGIVER';
const LAB_USER      = 'LAB_USER';

const { Panel } = Collapse;
const { Title } = Typography;

const iconStyleLarge = {
    width: 25,
    height: 25
}

const customPanelStyle = {
    background: 'white',
    borderRadius: 4,
    marginBottom: 24,
    border: 0,
    overflow: 'hidden',
};

const CancelToken = axios.CancelToken;
const { Text } = Typography;
const { Option } = Select;
const { confirm } = Modal;

const FIRST_PIPELINE = 'FIRST_RUN'
const PIPELINE_DONE = 'DONE'
const PIPELINE_CONFIRMED = 'CONFIRMED'
const CATEGORIES = ['PRESENT', 'UNKNOWN', 'EXCLUDED']
const HPO_URL = 'https://hpo.jax.org/app/browse/term/'

const booleanIconStyle = () => {
    return { color: 'rgb(23, 144, 255)', fontSize: '1.45em', display: 'inline-block' }
}

const HELP_MESSAGE =
    <div>
        <p>
        <b>Task 1:</b> You can describe your patient’s phenotype more completely. <br/>
            We have provided a list of phenotypic features in the Unknown box. You can drag and drop
these to the Present and Excluded columns.  Features that you can’t categorize can be left
in the Unknown box. If you don’t see a phenotypic feature in the Unknown box that you’d like to add, you can start
to type in the <b>Add Phenotype Feature</b> box and select the appropriate term.  It will end up in
the Present box.
        </p>

        <p>
        <b>Task 2:</b> You can flag a disease that you think may be affecting your patient.<br/>
        The list of possible diseases is ranked by probability, based on the patient’s phenotype and genetic information. <br/>
To learn more about a disease, click on the disease name. <br/>
If a disease looks like a good fit for your patient, click on the flag on the right side for that disease. <br/>
        </p>

        <p>
        <b>Task 3:</b> When you have completed your tasks, scroll to the bottom of the page and click Report to send the information to the diagnostic laboratory.
        </p>

    </div>

const SUBMIT_MESSAGE =
    <div>
        <span>The list of possible diseases is ranked by probability, based on the patient’s phenotype and genetic information. To learn more about a disease, click on the disease name. If a disease looks like a good fit for your patient, click on the flag on the right side for that disease.</span>
    </div>


const CHART_MESSAGE = <div>
    <span>The disease list below is ranked by probability based on the patient’s genetic and phenotypic
information.  If a disease is highly-ranked and has a reasonable probability, you can flag the disease
below as a candidate for your patient’s problems.  
You can also see which phenotypic terms make each disease more (blue) or less (orange)
likely.</span>
</div>


const SUBMITTED_MESSAGE = <div style={{ padding: '20px 20%' }}>
    <p>
        Thanks for refining your patient’s phenotype. GenomeDiver will now look for phenotype-consistent diseases suggested by the patient's DNA sequence. <br />
        When the <Icon type='thunderbolt' theme='filled' style={booleanIconStyle()} /> reappears in your patient’s record (in ~30 minutes), you can continue to Explore findings. <br />
        Until then, you can continue other dives in your <Link to='/caregiver'>patient list</Link>
    </p>
</div>

const CHOOSE_DIVE = <span>Begin by selecting a dive.</span>

const START = 'REPORT'
const UPDATE = 'UPDATE'

// Phenotype update polling interval (3 seconds)
const REFRESH_INTERVAL = 3000;

// keep track of phenotype->attributes being juggled about locally in the 
// drag-n-drop tool. the reason for a local copy as opposed to just 
// props is to keep indexing information. 
const default_state = {
    'mode': 'refine',
    'prior_case_id': null,
    'prior_analysis_id': null,
    'pipeline': FIRST_PIPELINE,
    'local_phenotype': null,
    'local_phenotype_timestamp_l': Date.now(),
    'local_phenotype_timestamp_r': Date.now(),
    'update_phenotype': null,
    'update_phenotype_timestamp_l': Date.now(),
    'update_phenotype_timestamp_r': Date.now(),
    'local_disease': new Set(),
    'local_disease_timestamp_l': Date.now(),
    'local_disease_timestamp_r': Date.now(),
    'update_disease': null,
    'update_disease_timestamp_l': Date.now(),
    'update_disease_timestamp_r': Date.now(),
    'error_validation': [],
    'submitted_analysis': false,
    'lirical_request_start': null, 
    'lirical_request_result':null, 
    'lirical_data': null,
    'lirical_request_status': 'initial'
}

class RefinePhenotype extends PureComponent {

    constructor(props) {
        super(props);

        // processed patient phenotypes as state 
        this.state = { ...default_state }

        // axios request cancellation token 
        this.request_source = CancelToken.source();

        // TODO clean up request on update 
        this.refresh_phenotypes_interval = null;

        this.lirical_timeout = React.createRef();
    }

    updateLirical(ctx) {
        // submission to lirical affects only local state. 
        // - no need to interface with global state tree. 

        const { patient_id, mode } = ctx.props;
        const { local_phenotype, prior_analysis_id, lirical_request, lirical_request_status } = ctx.state;

        const present_phenotypes = _.filter(Object.values(local_phenotype.phenotype),
            (p) => { return p['category'] === 'PRESENT' });

        const excluded_phenotypes = _.filter(Object.values(local_phenotype.phenotype),
            (p) => { return p['category'] === 'EXCLUDED' });

        const payload = {
            'p': present_phenotypes,
            'e': excluded_phenotypes
        };

        ctx.setState({'lirical_request_start': payload})

        // null case - short circuit 
        if (present_phenotypes.length == 0) return;
        if (lirical_request_status == 'progress') return;

        ctx.setState({'lirical_request_status': 'progress'})
        
        // clear out all flagging, as new input was introduced. 
        Rest.removeDiseaseAssociation(prior_analysis_id, '*').then(() => {
            ctx.setState({
                'local_disease_timestamp_r': Date.now()
            })
        })

        Rest.updateLirical(
            patient_id,
            prior_analysis_id,
            _.map(present_phenotypes, (p) => p.hpo_id),
            _.map(excluded_phenotypes, (p) => p.hpo_id),
            mode === 'report'
        ).then(response => {
            if ('data' in response.data) {
                // was cancellation occured,. re-initialize 
                ctx.setState({'lirical_request_result': payload});
                const { lirical_request_start } = ctx.state;

                if (!_.isEqual(lirical_request_start, payload)) {
                    this.updateLirical(ctx);
                } else {
                    ctx.setState({
                        'lirical_data': response.data.data['hpo_disease_recommendation'],
                        'lirical_request_status': 'complete',
                        'local_disease': new Set(),
                        'local_disease_timestamp_l': Date.now()
                    })
                }
            }
        }).catch(() => {
            ctx.setState({
                'lirical_data': null,
                'lirical_request_status': 'error'
            })
        })
    }

    displayError(error) {

        // ignore cancellations 
        if (axios.isCancel(error)) {
            return; 
        }

        // TODO: need to catch generic/rest and graphql
        Modal.error({
            title:'Error',
            content: error.message || error.response.data
                .errors.map(d => d.message).join('\n'),
            onOk() {}
        });
    }

    showConfirm(ctx) {
        confirm({
            title: 'Are you sure?',
            content: 'Have you flagged any candidate diseases to send to the lab?. After clicking OK you can\'t change the phenotype data for this dive.',
            onOk() {
                const { patient } = ctx.props;
                const { error_validation, prior_case_id, prior_analysis_id } = ctx.state

                const select_case = patient && patient.case ? _.find(patient.case, { id: prior_case_id }) : false;
                const select_analysis = select_case && select_case.analysis ? _.find(select_case.analysis, { id: prior_analysis_id }) : false;
                const select_assembly = select_analysis && select_analysis.input ? _.find(select_analysis.input, { name: 'Reference' }) : false;

                let validation = []
                if (!select_case) { validation.push('Please specify a case for the patient.') }
                if (!select_analysis) { validation.push('Please select an analysis to continue.') }
                if (!select_assembly) { validation.push('Assembly not found.') }

                // // update the display of validation errors 
                if (!_.isEqual(error_validation.sort(), validation.sort())) {
                    ctx.setState({ error_validation: validation })
                }

                // // no errors - submit analysis 
                if (_.isEmpty(validation)) {

                    Rest.confirmAnalysis([prior_analysis_id])
                        .then(response => { return gqlError(response) })
                        .then(response => {
                            message.success('Dive session confirmed');
                            ctx.props.goHome();
                        })
                        .catch((error) => { ctx.displayError(error) });
                }
            }
        });
    }

    onChangeHPO(hpo_list) {
        this.props.patientPhenotype(this.props.patient_id);
        this.checkPhenotypes(0);
    }

    componentDidMount() {
        const { mode, case_id, analysis_id } = this.props;
        // Two different modes:
        // - mode:refine get patient data 
        // - mode:report patient detail is defined basically just pull 
        if (mode === 'refine') this.props.patients(this.props.patient_id);

        let update_state = { ...default_state }

        if (case_id) update_state['prior_case_id'] = case_id
        if (analysis_id) update_state['prior_analysis_id'] = analysis_id

        // set initial state
        this.setState(update_state);
        this.checkPhenotypes(0);
    }

    componentWillUnmount() {
        clearTimeout(this.refresh_phenotypes_interval);
    }

    checkPhenotypes(duration) {
        this.refresh_phenotypes_interval = setTimeout(
            this.checkPhenotypeChanges.bind(this), duration
        );
    }

    debouncedUpdateLirical(ctx) {
        //ctx.setState({ 'lirical_request_status': 'progress' })
        clearTimeout(ctx.lirical_timeout.current);
        ctx.lirical_timeout.current = setTimeout(() => {
            ctx.updateLirical(ctx);
        }, 1000);
    }

    checkPhenotypeChanges() {
        const { local_phenotype, prior_analysis_id, lirical_data, lirical_request_status } = this.state;
        const { patient_id, mode } = this.props;

        if (local_phenotype && prior_analysis_id) {

            this.setState({
                update_phenotype_timestamp_l: Date.now(),
                update_disease_timestamp_l: Date.now()
            })

            Rest.getAnalysisDetailPhenotypes(patient_id, prior_analysis_id).then((response) => {
                const update_phenotype = response.data.data.analysis[0].phenotypes;
                const update_disease = new Set(
                    _.map(response.data.data.analysis[0].disease_associations, (d) => d.disease))

                // make sure analysis id hasn't shifted while the request is in transit.
                if (this.state.prior_analysis_id === prior_analysis_id) {
                    this.setState({
                        update_phenotype,
                        update_phenotype_timestamp_r: Date.now(),
                        update_disease,
                        update_disease_timestamp_r: Date.now()
                    });
                }

                if (!lirical_data && lirical_request_status != 'progress') {
                    this.debouncedUpdateLirical(this)
                }

            }).catch(() => {
                // do nothing 
            }).finally(() => {
                //if (mode === 'refine') {
                this.checkPhenotypes(REFRESH_INTERVAL);
                //}
            })

        } else {
            //if (mode === 'refine') {
            this.checkPhenotypes(REFRESH_INTERVAL);
            //}
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { analysis_status, analysis_message, patient } = this.props;
        const {
            prior_case_id, prior_analysis_id,
            local_phenotype, update_phenotype,
            local_phenotype_timestamp_l, local_phenotype_timestamp_r,
            update_phenotype_timestamp_l, update_phenotype_timestamp_r,
            local_disease, update_disease,
            local_disease_timestamp_l, local_disease_timestamp_r,
            update_disease_timestamp_l, update_disease_timestamp_r
        } = this.state;

        if (prevState.prior_analysis_id !== this.state.prior_analysis_id) {
            // there is a *good* chance that local phenotypes are out of sync 
            // reload patient details and re-derive local_phenotype
            // this.props.patients(this.props.patient_id);
            this.props.patientPhenotype(this.props.patient_id);
        }

        if (patient && patient.case && !prior_case_id && !prior_analysis_id) {
            const dives = diveTree(patient.case, FIRST_PIPELINE, PIPELINE_DONE);
            if (dives.length === 1) {
                this.selectCaseAnalysis(`${dives[0].case_id}-${dives[0].analysis[0].id}`)
            }
        }

        // default choice when there is only one dive to choose from 
        if (patient && patient.case && !prior_case_id && !prior_analysis_id) {
            //console.log('default choice !!!!! ')
            //this.selectCaseAnalysis(`${patient.case[0].id}-${patient.case[0].analysis[0].id}`)
        }

        if (analysis_status === 'ANALYSIS_REQUEST_ERROR') {
            const ctx = this;
            Modal.error({
                title: 'Error',
                content: analysis_message,
                onOk() {
                    ctx.props.cancelAnalysis();
                }
            })
        }

        if (analysis_status === 'ANALYSIS_REQUEST_SUCCESS') {
            this.props.completeAnalysis();
            this.setState({ submitted_analysis: true })

            //setTimeout(() => this.props.goHome(), 1000);
        }

        if (local_disease && update_disease
            && local_disease_timestamp_l < local_disease_timestamp_l
            && update_disease_timestamp_l < update_disease_timestamp_r
            && update_disease_timestamp_r > local_disease_timestamp_r) {

            if (!_.isEqual(local_disease, update_disease)) {
                this.setState({ 'local_disease': update_disease });
            }
        }

        if (local_phenotype && update_phenotype
            && local_phenotype_timestamp_l < local_phenotype_timestamp_r
            && update_phenotype_timestamp_l < update_phenotype_timestamp_r
            && update_phenotype_timestamp_r > local_phenotype_timestamp_r) {
            const updatedPhenotypes = _.sortBy(RefinePhenotype.phenotypesOfAnalysis(update_phenotype, prior_analysis_id, FIRST_PIPELINE, PIPELINE_DONE), ['id']);

            _.forEach(updatedPhenotypes, (u, i) => {
                // u is the updated phenotype 
                // l is the local phenotype 
                const l = _.find(Object.values(local_phenotype.phenotype), (p) => { return p.id === u.id })

                if (l['important'] !== u['important']) {
                    notification.warn({
                        message: 'PHENOTYPE STAR UPDATED',
                        description: `${u['hpo_term']}: (${l['important']} to ${u['important']})`,
                        duration: 5
                    });

                    const draggableId = _.findKey(local_phenotype.phenotype, { id: l.id })
                    this.onStar(draggableId, l.id, u['important'] | 0)
                }

                if (l['category'] !== u['category']) {
                    notification.warn({
                        message: 'PHENOTYPE CATEGORY UPDATED',
                        description: `${u['hpo_term']}: (${l['category']} to ${u['category']})`,
                        duration: 5
                    });

                    const draggableId = _.findKey(local_phenotype.phenotype, { id: l.id })
                    const source_idx = local_phenotype.category[l['category']].ids.indexOf(draggableId);
                    const drag_event = {
                        combined: null,
                        mode: 'FLUID',
                        reason: 'DROP',
                        type: 'DEFAULT',
                        draggableId,
                        source: { index: source_idx, droppableId: l['category'] },
                        destination: { index: 0, droppableId: u['category'] }
                    }

                    this.onDragEnd(drag_event);
                }
            });
        }
    }

    static phenotypesOfAnalysis(phenotypes, analysis_id, pipeline, status) {

        // filter only phenotypes that are relevant to the analysis & and pipeline 
        // that are in focus. 
        return (analysis_id && pipeline) ? _.filter(phenotypes, (p) => {
            return ('of_analysis' in p) &&
                (p['of_analysis'].length === 1) &&
                (p['of_analysis'][0].pipeline === pipeline) &&
                (p['of_analysis'][0].id === analysis_id) &&
                (p['of_analysis'][0].status === status)
        }) : phenotypes
    }

    static getDerivedStateFromProps(props, state) {

        // method occurrs before render - creates a local copy of the phenotype 
        // data structure 
        const { patient, mode } = props;
        const { prior_case_id, prior_analysis_id, local_phenotype, update_phenotype } = state;
        const status = (mode === 'report') ? PIPELINE_CONFIRMED : PIPELINE_DONE;

        if (patient) {

            const relevantPhenotypes = _.sortBy(RefinePhenotype.phenotypesOfAnalysis(patient.phenotypes, prior_analysis_id, FIRST_PIPELINE, status), ['id']);
            const updatedPhenotypes = _.sortBy(RefinePhenotype.phenotypesOfAnalysis(update_phenotype, prior_analysis_id, FIRST_PIPELINE, status), ['id'])

            // refresh list if new phenotypes are discovered. phenotypes are only monotonically added 
            let to_process_phenotypes = relevantPhenotypes

            // re-assign to_process_phenotypes to updated if there is a phenotype added. 
            if (local_phenotype && updatedPhenotypes.length > Object.keys(local_phenotype.phenotype).length) {
                to_process_phenotypes = updatedPhenotypes;

                const added_pheno = new Set(_.difference(to_process_phenotypes.map(p => { return p.id }),
                    Object.values(local_phenotype.phenotype).map(p => { return p.id })))

                const added_pheno_disp = to_process_phenotypes.filter(p => {
                    return added_pheno.has(p.id);
                }).map(p => {
                    return p.hpo_term;
                }).join(',');

                notification.warn({
                    message: 'PHENOTYPE ADDED',
                    description: added_pheno_disp,
                    duration: 5
                });
            }

            // initial state & used updated 
            if ((prior_analysis_id && !local_phenotype) ||
                (local_phenotype && (to_process_phenotypes === updatedPhenotypes))) {

                // refresh drag/drop states - hopefully not too compute intensive 
                return {
                    prior_case_id,
                    prior_analysis_id,
                    'local_phenotype': _.cloneDeep(phenotypesToDrag(to_process_phenotypes, CATEGORIES)),
                    'lirical_data': null,
                    'lirical_request_status': 'initial'
                }
            }
        }

        // default do nothing 
        return null;
    }

    selectCaseAnalysis = (case_analysis) => {
        const split = case_analysis
            .split('-')
            .map(i => { return Number.parseInt(i) })

        this.setState({
            prior_case_id: split[0],
            prior_analysis_id: split[1],
            local_phenotype: null
        });
    }


    // DND need some work to 'persist' the draggable items 
    onDragEnd = (result) => {
        const { destination, source, draggableId } = result;
        const do_nothing = !destination || ((destination.droppableId === source.droppableId) && (destination.index === source.index))

        // conditions for no-op; just return the drag operation 
        if (do_nothing) { return; }

        // pheno_state is the original constructed phenotype state 
        const pheno_state = this.state.local_phenotype;
        const start_column = pheno_state.category[source.droppableId];
        const end_column = pheno_state.category[destination.droppableId];

        // remove 'draggableId' from source column 
        const ids_2 = Array.from(start_column.ids);
        ids_2.splice(source.index, 1);

        // change server side 
        const phenotype_id = pheno_state.phenotype[draggableId].id

        if (start_column.id === end_column.id) {

            // insertion into the same column           
            ids_2.splice(destination.index, 0, draggableId)

            // updated column (essentially a reordering of the index)
            const column_2 = {
                ...start_column,
                ids: ids_2
            }

            const pheno_value = pheno_state.phenotype[draggableId];
            this.setState({
                local_phenotype: {
                    ...pheno_state,
                    phenotype: {
                        ...pheno_state.phenotype,
                        [draggableId]: {
                            ...pheno_value,
                            category: end_column.id
                        }
                    },
                    category: {
                        ...pheno_state.category,
                        [start_column.id]: column_2
                    }
                },
                local_phenotype_timestamp_l: Date.now()
            });

        } else {

            // insert into the separate column 
            const ids_3 = Array.from(end_column.ids);
            ids_3.splice(destination.index, 0, draggableId);

            // _2 (start) has the  excised item 
            const column_2 = {
                ...start_column,
                ids: ids_2
            }

            // _3 (end) has the same item inserted at the index 
            const column_3 = {
                ...end_column,
                ids: ids_3
            }

            const pheno_value = pheno_state.phenotype[draggableId];
            this.setState({
                local_phenotype: {
                    ...pheno_state,
                    phenotype: {
                        ...pheno_state.phenotype,
                        [draggableId]: {
                            ...pheno_value,
                            category: end_column.id
                        }
                    },
                    category: {
                        ...pheno_state.category,
                        [start_column.id]: column_2,
                        [end_column.id]: column_3
                    }
                },
                local_phenotype_timestamp_l: Date.now()
            });
        }

        Rest.modifyPhenotypeCategory(phenotype_id, destination.droppableId)
            .then((response) => {
                this.setState({ local_phenotype_timestamp_r: Date.now() })
                this.debouncedUpdateLirical(this);
            })
            .catch(error => {
                // revert state
                this.setState({ pheno_state, local_phenotype_timestamp_r: Date.now() })

                console.log(error)
                Modal.error({
                    title: 'Error',
                    content: error.response.data.errors.map(d => d.message).join('\n'),
                    onOk() { }
                })
            })
    }

    onDiseaseStar = (prior_analysis_id, disease) => {
        const local_disease_update = new Set(this.state.local_disease)

        if (local_disease_update.has(disease)) {
            Rest.removeDiseaseAssociation(prior_analysis_id, disease)
                .then(response => {
                    local_disease_update.delete(disease)
                    this.setState({ 'local_disease': local_disease_update })
                })
        } else {
            Rest.addDiseaseAssociation(prior_analysis_id, disease)
                .then(response => {
                    local_disease_update.add(disease)
                    this.setState({ 'local_disease': local_disease_update })
                })
        }
    }

    onStar = (index_id, pheno_id, value) => {
        const { local_phenotype } = this.state;
        const pheno_value = local_phenotype.phenotype[index_id];
        const new_local_phenotype = {
            ...local_phenotype,
            phenotype: {
                ...local_phenotype.phenotype,
                [index_id]: {
                    ...pheno_value,
                    important: !!value
                }
            }
        }

        this.setState({
            local_phenotype: new_local_phenotype,
            local_phenotype_timestamp_l: Date.now(),
        });

        Rest.modifyPhenotypeImportance(pheno_id, !!value)
            .then((response) => {
                this.setState({ local_phenotype_timestamp_r: Date.now() })
            })
            .catch(error => {
                this.setState({ local_phenotype, local_phenotype_timestamp_r: Date.now() })
                Modal.error({
                    title: 'Error',
                    content: error.response.data.errors.map(d => d.message).join('\n'),
                    onOk() { }
                })
            });
    }

    truncateExplanation = (exp) => {
        if (exp.indexOf('log') === 0) exp = exp.substring(exp.indexOf('. ') + 2);
        if (exp.indexOf('P(G') === 0) exp = exp.substring(exp.indexOf('. ') + 2);

        const titleCase = (text) => {
            return text.charAt(0).toUpperCase() + text.substr(1).toLowerCase();
        }

        // Isolate disease inheritance.
        exp = exp.substring(0, exp.indexOf('.') + 1);
        exp = exp.replace('Mode of inheritance', 'Disease Inheritance').trim();
        const exp_s = _.map(exp.split(':'), (t) => titleCase(t.trim()));
        exp = exp_s.join(': ');
        return exp
    }

    formatClinvarSignificance = (clinvar_sig) => {
        switch (clinvar_sig) {
            case 'PATHOGENIC':
            case 'PATHOGENIC_OR_LIKELY_PATHOGENIC':
            case 'LIKELY_PATHOGENIC':
            // In this scenario we have conflicting interpretations, with 
            // a likely pathogenic / pathogenic assertion found 
            case 'CONFLICTING_PATHOGENICITY_INTERPRETATIONS_P':
                return 'Likely causal'
            
            default:
                return 'Suggestive'
        }
    }

    formatMatchType = (liricalMatchType, variants, explanation) => {

        switch (liricalMatchType) {
            case 'NO_VARIANTS_DETECTED_AD':
                return 'Gene with autosomal dominant inheritance. No damaging variants detected.'

            case 'NO_VARIANTS_DETECTED_AR':
                return 'Gene with autosomal recessive inheritance. No damaging variants detected.'

            case 'ONE_DELETERIOUS_CLINVAR_VARIANT_IN_AD':
            case 'ONE_P_OR_LP_CLINVAR_ALLELE_IN_AD':
                return 'Gene with autosomal dominant inheritance. One ClinVar damaging variant detected.'

            case 'TWO_DELETERIOUS_CLINVAR_VARIANTS_IN_AR':
            case 'TWO_P_OR_LP_CLINVAR_ALLELES_IN_AR':
                return 'Gene with autosomal recessive inheritance. Two ClinVar damaging variants detected.'

            case 'ONE_DELETERIOUS_VARIANT_IN_AR':
                // deprecated
                return 'Gene with autosomal recessive inheritance. Two predicted damaging variants detected.'

            case 'HIGH_NUMBER_OF_OBSERVED_PREDICTED_PATHOGENIC_VARIANTS':
                return 'More than 2 ClinVar and/or predicted damaging variants detected.';

            case 'LIRICAL_GT_MODEL':
                return this.truncateExplanation(explanation)

            default:
                return 'Unknown';

        }
    }

    formatGenotype = (genotype) => {
        // consider regex \d|\d to adddress all the edge cases 
        // lirical does multiallic splitting to bialleic so likely no 1/2
        // variant ./1
        // variant ./1

        switch (genotype) {
            case '0|1':
            case '1|0':
            case '0/1':
            case './1':
                return 'Heterozygous'

            case '1/1':
            case '1|1':
            case '2/2': 
            case '2|2':
                return 'Homozygous'
            
            default:
                // catch all
                return genotype
        }
    }

    formatVariantData = (variants, geneObj) => {
        return _.map(variants, (v, i) => {
            return  {
                'key': i,
                'variant': geneObj[1] ? geneObj[1] : '',
                'details': ['chr'+v['chrom'],v['pos'],v['ref'],v['alt']].join('-'),
                'genotype': v['genotype'] ? this.formatGenotype(v['genotype']) : './.',
                'clin_sig': v['clinvar_allele_data'] ? v['clinvar_allele_data']['clin_sig'] : '',
                'allele_id': v['clinvar_allele_data'] ? v['clinvar_allele_data']['allele_id'] : ''
            }
        })
    }

    formatLiricalHeading = (heading) => {
        const heading_regex = /(.+)\s\[(\w+:\d+)\]/;
        const match = heading.match(heading_regex);
        return match;
    }

    onDiseaseLabelClick = (e) => {
        window.location.hash = e.value;
    }

    render() {
        const { patient_id, patient, showInfo, mode, role, set_survey_visible } = this.props;
        const { submitted_analysis, prior_case_id, prior_analysis_id, local_disease,
            local_phenotype, error_validation, lirical_data, lirical_request_status } = this.state;

        const select_key = prior_case_id && prior_analysis_id ?
            `${prior_case_id}-${prior_analysis_id}` : undefined;

        // (local) patient exist and is up same as the recommended one
        const valid_patient = patient && (Number.parseInt(patient_id) === Number.parseInt(patient.id))
        const dives = valid_patient ? diveTree(patient.case, FIRST_PIPELINE, PIPELINE_DONE) : undefined
        const disabled_submit = local_phenotype ? false : true

        // use the phenotype order of the highest ranking disease. 
        const pheno_sort_order = lirical_data ? _.map(_.sortBy(lirical_data[0]['observed_results'], ['lr']), r => r['query_term']) : [];
        
        const lirical_values = lirical_data ? _.flatMap(
            _.map(lirical_data, r =>
                _.map(r['observed_results'],
                    o => Math.log10(o['lr'])))
        ) : [0]

        const variant_columns = [
            {
                'title':'Variant',
                'dataIndex': 'variant', 
                'key': 'variant',
                render: (text, record) => 
                    <ul>
                        <li>
                            <a target='_blank' href={`https://varsome.com/variant/hg38/${record.details}`}><i>{text}</i></a>
                        </li>
                        <li><Text type='secondary' style={{maxWidth:'200px'}} ellipsis={true}>{record.details}</Text></li>
                    </ul>
            }, {
                'title': 'Genotype',
                'dataIndex': 'genotype',
                'key': 'genotype'
            }, {
                'title': 'Interpretation',
                'dataIndex': 'clin_sig',
                'key': 'clin_sig',
                render: (text, record) => <span>{this.formatClinvarSignificance(text) !== '' ? this.formatClinvarSignificance(text)
                : 'Other' }</span>
            }, {
                'title': 'Evidence',
                'dataIndex': 'clin_sig',
                'key': 'clin_sig_link',
                render: (text, record) => <span>{record.allele_id !== '' ? 
                <a target='_blank' href={`https://www.ncbi.nlm.nih.gov/clinvar/?term=${record.allele_id}[alleleid]/`}>ClinVar <Icon type="star" style={{color:'red'}} theme="filled" /></a>
                : 'Predictive Only' }</span>
            }]

        // post-test probability chart 
        const diseases = lirical_data ? _.reverse(_.map(lirical_data, r => r['disease'])) : []
        const posttest = lirical_data ? _.reverse(_.map(lirical_data, r => r['posttest_probability'])) : []
        const pp_chart = {
            textStyle: {
                fontFamily: 'Roboto'
            },
            xAxis: {
                type: 'value',
                min: 0,
                max: 1,
                axisLabel: {
                    formatter: (v) => v * 100 + '%'
                },

            },
            grid: {
                bottom: '3%',
                left:0,
                right:'10px',
                top: 0,
                containLabel: true
            },
            yAxis: {
                type: 'category',
                data: _.map(diseases, (d) => {return this.formatLiricalHeading(d)[1]}),
                axisLabel: {
                    fontSize: 14,
                    width:200,
                    overflow:'truncate'
                },
                triggerEvent: true
            },
            series: [
                {
                    type: 'bar',
                    data: posttest,
                    itemStyle: {
                        color: '#aadef3',
                        opacity: 1
                    }
                }
            ]
        }
        const pp_events = {
            'click': this.onDiseaseLabelClick
        }
   
        // make the survey button appear 
        if (set_survey_visible && role == CAREGIVER && mode === 'report' && lirical_request_status === 'complete') {
            set_survey_visible(true);
        }

        return (
            <>
            
            <Card className={mode === 'report' ? 'mode-report' : ''}>
                {submitted_analysis &&
                    <CSSTransition in={submitted_analysis}
                        timeout={0} classNames='result' unmountOnExit appear>
                        <Result
                            icon={<Icon type='reload' theme='outlined' />}
                            title='Analyzing'
                            subTitle={SUBMITTED_MESSAGE} />
                    </CSSTransition>
                }

                {!submitted_analysis && valid_patient &&
                    <Row gutter={8} style={flexStyle}>
                        <Col span={24} offset={0}>

                            {mode === 'refine' && <h2 className='gd-heading'><b>Task 1:</b> You can describe your patient’s phenotype more completely.</h2>}
                            {mode === 'report' && <h2 className='gd-heading' style={{visibility:'visible'}}  >Dive #{prior_analysis_id} report</h2>}
                            {mode === 'report' && lirical_request_status === 'progress' &&
                                <Row style={{ textAlign: 'center', margin: '1em' }}>
                                    <Spin style={{visibility:'visible'}} indicator={loadingIcon} />
                                </Row>}
                            {mode === 'report' && lirical_request_status === 'complete' && 
                                <Row style={{visibility:'visible', marginBottom:15}} >
                                    <Button onClick={()=>window.print()}>Click here to print report</Button>
                                </Row>}
                            

                            {/* Help Message + Patient Badge*/}
                            {showInfo && <Row><Alert style={{ margin: '0 0 12px 0' }} description={HELP_MESSAGE} type='info' showIcon /></Row>}

                            {dives.length > 1 && <Row><Alert stype={{ margin: '0 0 12px 0' }} description={CHOOSE_DIVE} type='warning' showIcon /></Row>}

                            {/* Choose an (Prior) Analysis to continue*/}
                            {mode === 'refine' &&
                                <Row style={{ marginTop: 15 }}>
                                    {dives && dives.length > 0 && <Col span={14}>
                                        <HPOSelector
                                            patient={patient}
                                            css='ontology-select min'
                                            search={true}
                                            mode='multiple'
                                            size='large'
                                            analysis={prior_analysis_id}
                                            placeholder='i.e. Intellectual Disability'
                                            category="PRESENT"
                                            onChangeHPO={(h) => this.onChangeHPO(h)} />
                                    </Col>}

                                    {dives && dives.length > 0 &&
                                        <Col span={10} offset={0} style={{ paddingLeft: 12, gap: 10, display: 'flex', flexDirection: 'column' }}>
                                            {/* dives.length > 1   && <h3>Please pick a dive for {patient.first_name_enc}</h3>}
                                    {dives.length === 1 && <h3>Dive for {patient.first_name_enc}</h3> */}
                                            <PatientDiveBadge patient={patient} />
                                            <Select
                                                size='large'
                                                style={{ width: '100%' }}
                                                value={select_key ?
                                                    select_key : undefined}
                                                placeholder='Select a Dive'
                                                disabled={dives.length <= 1}
                                                onChange={this.selectCaseAnalysis}>

                                                {/* Render Dives -> Analysis */}
                                                {dives.map((dive, dkey) =>
                                                    <Option
                                                        key={`${dive.case_id}-${dive.analysis[0].id}`}
                                                        value={`${dive.case_id}-${dive.analysis[0].id}`}>

                                                        &nbsp;<Text strong>Dive #{dive.analysis[0].id}</Text>&nbsp;
                                                        <Text type='secondary'>({(new Date(dive.analysis[0].time_started)).toLocaleDateString()})</Text>&nbsp;
                                                        <Text code>{dive.label.toUpperCase()}</Text>
                                                    </Option>
                                                )}
                                            </Select>
                                        </Col>}

                                    {dives && dives.length === 0 &&
                                        <Col span={24}>
                                            <Result
                                                status='404'
                                                title='NOT FOUND'
                                                subTitle='No dives to continue.'
                                            />
                                        </Col>}
                                </Row>}

                            {/* Drag and Drop Implementation using Beautiful React DND */}
                            {prior_analysis_id && local_phenotype &&
                                <Row>
                                    <div className='refine-dnd-wrapper'>
                                        <div className='refine-dnd-context'>
                                            <DragDropContext onDragEnd={this.onDragEnd}>
                                                {local_phenotype.categoryOrder.map(category_id => {
                                                    if (mode == 'report' && category_id === 'UNKNOWN') return <div key={category_id}></div>
                                                    return (
                                                        <PhenotypeCategory
                                                            onStar={this.onStar}
                                                            key={category_id}
                                                            category_id={category_id}
                                                            category={local_phenotype.category[category_id]}
                                                            phenotype_data={local_phenotype.phenotype}>
                                                        </PhenotypeCategory>
                                                    )
                                                }
                                                )
                                                }
                                            </DragDropContext>
                                        </div>
                                    </div>
                                </Row>
                            }
                            {/* ===================================================
                                Errors: Validation 
                                =================================================== 
                            */}
                            {error_validation.length > 0 &&
                                <Row style={{ marginTop: 20 }}>
                                    <Alert type='error' showIcon message={error_validation.length > 1 ?
                                        <ul>{error_validation.map(e => (<li key={e}>{e}</li>))}</ul>
                                        : <span>{error_validation[0]}</span>
                                    } />
                                </Row>}
                        </Col>
                    </Row>}
                
                {!submitted_analysis && !valid_patient &&
                    <Row style={{ textAlign: 'center', margin: '10em' }}>
                        <Spin indicator={loadingIcon} />
                    </Row>}
                
                {lirical_request_status == 'complete' && lirical_data && 
                    <Row style={{ textAlign: 'left', marginTop: '1em' }}>    
                        <h2 className='gd-heading'><b>Task 2:</b> Flag a disease below that you think might be affecting your patient</h2>
                    </Row>}

                {lirical_request_status === 'progress' &&
                    <Row style={{ textAlign: 'center', margin: '10em' }}>
                        <Spin indicator={loadingIcon} />
                    </Row>}
                
                <Row>
                    {lirical_request_status == 'complete' && lirical_data &&
                        <div className='chart-container'>
                            <Title level={4}>Genetic diseases that could be affecting patient</Title>
                            <ReactECharts
                                style={{ height: diseases.length * 40, fontSize: 15 }}
                                option={pp_chart}
                                onEvents={pp_events}
                                notMerge={true}
                                lazyUpdate={true} />
                        </div>}
                </Row>

                <Row>
                    {lirical_request_status === 'complete' &&
                        lirical_data && lirical_data.map((result, index) => {
                            const labelRight = { position: 'right' }
                            const labelLeft = { position: 'left' }
                            const chart_data = _.map(
                                _.sortBy(result['observed_results'], [r => {
                                    return pheno_sort_order.indexOf(r['query_term'])
                                }]), 
                                v => {
                                    const exp = v['explanation']
                                    // TODO: explanation has too much text. currently maybe too little. 

                                    return {
                                        label: exp.substring(exp.indexOf(':') + 1, exp.indexOf('[')),
                                        value: Math.log10(v['lr']).toPrecision(4)
                                    }
                                })

                            const option = {
                                textStyle: {
                                    fontFamily: 'Roboto'
                                },
                                tooltip: {
                                    trigger: 'axis',
                                    axisPointer: { type: 'none' }
                                },
                                grid: {
                                    top: 40,
                                    bottom: 20,
                                    height: chart_data.length * 50
                                },
                                xAxis: {
                                    type: 'value', position: 'top',
                                    min: Math.min(...lirical_values).toPrecision(4),
                                    max: Math.max(...lirical_values).toPrecision(4),
                                    splitLine: { lineStyle: { type: 'dashed' } }
                                },
                                yAxis: {
                                    type: 'category',
                                    axisLine: { show: false },
                                    axisLabel: { show: false },
                                    axisTick: { show: false },
                                    splitLine: { show: false },
                                    data: _.map(chart_data, v => { return v['label'] })
                                },
                                series: [
                                    {
                                        name: 'Log(LR)',
                                        type: 'bar',
                                        cursor: 'default',
                                        stack: 'Total',
                                        label: {
                                            show: true,
                                            formatter: '{b}',
                                            fontSize: 14
                                        },
                                        data: _.map(chart_data, v => {

                                            // const item_color = (v.value > 0 ) ? 'rgb(170 222 243)' : 'rgb(252 214 167)'
                                            const item_color = (v.value > 0) ? '#aadef3' : '#fcd6a7'
                                            const label = v.value > 0 ? labelLeft : labelRight

                                            return {
                                                label: label,
                                                value: v.value,
                                                itemStyle: {
                                                    color: item_color,
                                                    opacity: 1
                                                }
                                            }
                                        })
                                    }
                                ]
                            }

                            const resultDiseaseObj = this.formatLiricalHeading(result.disease)
                            const resultGeneObj = this.formatLiricalHeading(result.gene)

                            return (
                                <div id={result.disease} key={index} className='chart-container'>
                                    <a id={resultDiseaseObj[1]}/>
                                    <Row className='lirical-chart'>
                                        <Col span={22}>
                                            <Title level={4}>
                                                <DiseaseLink 
                                                    disease={resultDiseaseObj[2]} 
                                                    title={resultDiseaseObj[1]}
                                                    paren={resultDiseaseObj[2]}
                                            /></Title>
                                            <Row style={{ marginBottom: 15 }}>
                                                <Col span={23}>
                                                <span style={{ 
                                                    fontSize: 20, cursor:'pointer', zIndex:2, color:'#999'}} 
                                                    onClick={(v) => this.onDiseaseStar(prior_analysis_id, result.disease)}>

                                                    <span className="ant-rate-text" style={{marginRight:'8px'}}>Flag if strong disease candidate</span>
                                                    <Rate
                                                        character={<Icon type='flag' theme='filled' />}
                                                        defaultValue={local_disease.has(result.disease) | 0}
                                                        value={local_disease.has(result.disease) | 0} count={1}/>
                                                </span>
                                                </Col>
                                            </Row>
                                            <Collapse bordered={false} defaultActiveKey={['1']}>
                                                <Panel header='Variant details' key='1' style={customPanelStyle}>
                                                    <Row style={{ marginBottom: 15 }}>
                                                        {/* <Col style={{ paddingLeft: 10 }}>
                                                            {<span dangerouslySetInnerHTML={{ __html: this.truncateExplanation(result.explanation) }} />}
                                                        </Col> */}
                                                        <Col style={{ paddingLeft: 10, paddingTop: 10 }}>
                                                            <span>{this.formatMatchType(result.match_type, result.variants, result.explanation)}</span>
                                                            {result.variants && result.variants.length > 0 &&
                                                                <Table
                                                                    className="variant-details"
                                                                    dataSource={this.formatVariantData(result.variants, resultGeneObj)}
                                                                    columns={variant_columns}
                                                                    style={{ margin: '14px 0', width: '85%' }}
                                                                    pagination={false}
                                                                    size='small' />}
                                                        </Col>
                                                    </Row>
                                                </Panel>
                                            </Collapse>
                                            <ReactECharts style={{ height: chart_data.length * 50 + 80 }} option={option} notMerge={true} lazyUpdate={true} />
                                        </Col>
                                        <Col span={4}></Col>
                                    </Row>
                                </div>);
                        })}
                </Row>

                {mode === 'refine' &&
                    <Row style={{ marginTop: 20 }}>
                        <Col span={4}>
                            <Button icon='left' type='primary' onClick={this.props.goHome}>BACK</Button>
                        </Col>
                        {dives && dives.length > 0 && !submitted_analysis &&
                            <Col span={20} style={{ textAlign: 'right' }}>
                                <h2 className='gd-heading'><b>Task 3:</b> Submit your Evaluation</h2>
                                <Button disabled={disabled_submit}
                                    onClick={(e) => this.showConfirm(this)} type='primary'>{START}</Button>
                            </Col>
                        }
                    </Row>
                }
            </Card>
            
            </>
        )
    }
}

function mapStateToProps(store, ownProps) {
    let { PatientDetail, Analysis } = store;

    return {
        patient: PatientDetail.data && PatientDetail.data.patients ? PatientDetail.data.patients[0] : undefined,
        analysis_status: Analysis && Analysis.status ? Analysis.status : undefined,
        analysis_message: Analysis && Analysis.message ? Analysis.message : undefined
    };
}

function mapDispatchToProps(dispatch, ownProps) {
    return {
        patients: (id) => {
            dispatch(PatientDetail(id, true));
        },
        patientPhenotype: (id) => {
            dispatch(PatientDetailPhenotypes(id));
        },
        completeAnalysis: () => {
            dispatch(completeAnalysis());
        },
        cancelAnalysis: () => {
            dispatch(cancelAnalysis());
        },
        startAnalysisCare: (patient_id, case_id, pipeline, reference, parent_analysis_id) => {
            dispatch(startAnalysis(patient_id, case_id, pipeline, reference, null, parent_analysis_id));
        }
    }
};

export default connect(
    mapStateToProps, mapDispatchToProps
)(RefinePhenotype);