import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Radio, Space, Row, Col, Upload, message } from 'antd';
import { authHeader } from '../../helpers';
import '../styles/VCFUploader.css';

const Dragger = Upload.Dragger;
class VCFUploader extends PureComponent {

  render() {

    const { authInfo, patient, assembly,
      onChangeAssembly,
      onChangeProband,
      onChangeFather,
      onChangeMother
    } = this.props;

    function pedSettings(ped, onChangePed) {
      const ped_settings = {
        name: 'vcf',
        accept: '.vcf,.gz',
        multiple: false,
        action: `/upload/vcf/${patient.id}`,
        headers: { ...authHeader() },
        onChange(info) {
          const status = info.file.status;
          if (status === 'done') {
            message.success(`${info.file.name} file uploaded successfully`);
            onChangePed(info.file.response.vcf_namespace, info.file.response.sample_name)
          } else if (status === 'error') {
            message.error(`${info.file.name} file upload failed`);
          }
        },
      };

      return ped_settings;
    }

    return (
      <div>
        <Radio.Group
          style={{ marginBottom: 20 }}
          onChange={(e) => onChangeAssembly(e.target.value)} value={assembly}>
          <Radio.Button disabled value="GRCh37">GRCh37 (hg19)</Radio.Button>
          <Radio.Button value="GRCh38">GRCh38 (hg38)</Radio.Button>
        </Radio.Group>

        <div style={{ display: "flex", gap: 30, flexDirection: 'column' }}>
          <Row>
            <Col span={10}>
              {authInfo && <Dragger
                {...pedSettings("father", onChangeFather)}>
                <div style={{ minHeight: 235 }}>
                  <p className="ant-upload-text">[Optional]<br />FATHER</p>
                  <p>Either drag a VCF file here or click to upload</p>
                </div>
              </Dragger>}
            </Col>
            <Col span={10} offset={3}>
              {authInfo && <Dragger
                {...pedSettings("mother", onChangeMother)}>
                <div style={{ minHeight: 235 }}>
                  <p className="ant-upload-text">[Optional]<br />MOTHER</p>
                  <p>Either drag a VCF file here or click to upload</p>
                </div>
              </Dragger>}
            </Col>
          </Row>
          <Row style={{ textAlign: 'center', marginLeft: '-5%' }}>
            <img style={{ width: '50%' }} src="/images/pedigree-diagram.svg" />
          </Row>
          <Row>
            <Col span={10} offset={6}>
              {authInfo && <Dragger
                {...pedSettings("proband", onChangeProband)}>
                <div style={{ minHeight: 235, marginLeft: "5%" }}>
                  <p className="ant-upload-text"><b>[Required]</b><br />PROBAND</p>
                  <p>Either drag a VCF file here or click to upload</p>
                </div>
              </Dragger>}
            </Col>
          </Row></div>
      </div>
    )
  }
}

function mapStateToProps(store, ownProps) {
  const { Auth } = store;
  return {
    authInfo: Auth.info
  }
}

export default connect(
  mapStateToProps
)(VCFUploader);

// - [WORKED AROUND] Interesting GraphQL doesn't support UPLOAD 
// https://groups.google.com/forum/#!topic/sangria-graphql/nhIewAHZc_I
// GENIUS: https://gist.github.com/dwhitney/d50a47d8431ec18f6b32
// https://davidfrancoeur.com/post/akka-http-multipart-form/
