import React, { PureComponent } from 'react';
import './Home.css';
import { connect } from 'react-redux';
import { setHome } from '../../actions'

class Home extends PureComponent {

    componentWillMount() {
        this.props.setHome(true)
    }

    componentWillUnmount() {
        this.props.setHome(false)
        document.body.classList.toggle('home', false);
    }

    render() {
        // const role = (this.props.authInfo && this.props.authInfo.role) ? this.props.authInfo.role.toLowerCase().split('_')[0] : false

        return (
            <div>
                <div style={{
                    'position': 'absolute',
                    'display': 'flex',
                    'flexDirection': 'column',
                    'alignItems': 'center',
                    'justifyContent': 'center',
                    'width': '100%',
                    'height': '100%',
                    'top': 40,
                    'left': 0
                }}>

                    <h3 style={{
                        'fontWeight': 'bold',
                        'fontSize': 'x-large',
                        'color': 'white'
                    }}></h3>

                    <p style={{ 'color': 'white' }}></p>
                </div>
            </div>
        )
    }
}

function mapStateToProps(store, ownProps) {
    const { Auth, Connection } = store;

    return {
        authStatus: Auth.status,
        authInfo: Auth.info,
        connectionStatus: Connection.status
    }
}

function mapDispatchToProps(dispatch, ownProps) {
    return {
        setHome: (val) => {
            dispatch(setHome(val));
        }
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Home);
